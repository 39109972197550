<template>
  <div class="report">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-radio-group v-model="radio">
      <van-radio name="1">令人恶心，反感不适</van-radio>
      <van-radio name="2">含有不雅、色情内容</van-radio>
      <van-radio name="3">标题或内容夸张、诱导点击</van-radio>
      <van-radio name="4">虚假欺诈：卖假货、提供非法服务等</van-radio>
      <van-radio name="5">内容粗糙不美观</van-radio>
      <van-radio name="6">涉及侵权(知识产权等合法权益)</van-radio>
      <van-radio name="7">其他问题</van-radio>
      <van-radio name="8">对此类产品不感兴趣</van-radio>
    </van-radio-group>
    <p class="title">举报描述（选填）</p>
    <van-field
      class="text"
      v-model="message"
      rows="2"
      autosize
      type="textarea"
      maxlength="200"
      placeholder="详细描述举报理由..."
      show-word-limit
    />
    <div class="report_btn_wrapper">
      <van-button type="info" class="report_btn" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'report',
  data () {
    return {
      radio: null,
      message: null
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    submit () {
      if (!this.radio) {
        this.$toast('请选择投诉内容')
        return
      }
      this.radio = null
      this.message = null
      this.$toast('已收到您的举报投诉，感谢您对平台环境做出的贡献。')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .report
    background #191d3d
    height 100%
    .van-radio-group
      margin 20px 0
      .van-radio
        margin 10px 20px
    .title
      margin 10px
      font-weight bold
      color #fff
    .text
      width 90%
      margin 0 auto
      border 1px solid #e5e5e5
      border-radius 10px
    .report_btn_wrapper
      display flex
      margin-top 50px
      .report_btn
        width 95%
        margin 0 auto
        font-weight 500
        font-size 18px
        border-radius 10px
  >>> .van-radio__label
    color #fff
</style>